import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from "react-router-dom"
import Header from '../components/header/Header'
import ServicesDetails from "../components/servicesDetail/ServicesDetail"
import Footer from '../components/footer/Footer'
import WhyUs from '../components/servicesDetail/WhyUS'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import ContactInfo from '../components/contact_info/ContactInfo';
import SEO from '../common/SEO'
import servicesData from "../data/services.json";

const ServicesDetail = () => {
    // useEffect(() => {
    //     const selectedService = servicesData.find(service => service.type === type);
    //     setServiceDetails(selectedService);
    // }, [type])
    const { type } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [serviceDetails, setServiceDetails] = useState(null);
    
    useEffect(() => {
        // const selectedService = servicesData.find(service => service.type === type);
        
        // Convert type to lowercase
        const correctedSlug = type.toLowerCase();
        // If URL is not already lowercase, redirect to the correct URL
        if (type !== correctedSlug) {
            navigate(`/services/${correctedSlug}`, { replace: true });
            return;
        }

        // Find service details
        const selectedService = servicesData.find(service => service.type === correctedSlug);
        if (!selectedService) {
            // Redirect to 404 page if the service is not found
            navigate("*", { replace: true });
            return;
        }

        setServiceDetails(selectedService);
        
        // if (type !== correctedSlug) {
        //     navigate(`/services/${correctedSlug}`, { replace: true });
        // }
        // if (!selectedService) {
        //     navigate('*');
        //   } 

    }, [type, navigate]);
    // if (!serviceDetails) return <p>Loading...</p>;

    return (
        <>
            <SEO title={serviceDetails?.metaTitle} description={serviceDetails?.metaDescription} keywords={serviceDetails?.keyword}/>
            <Header />
            <InnerPageBanner title={serviceDetails?.bannerTitle} maxWidth='472px' innerPageBanner={serviceDetails?.bannerimage}/>
            <ServicesDetails />
            <WhyUs />
            <ContactInfo />
            <Footer />
        </>
    )
}

export default ServicesDetail;
