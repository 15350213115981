import React from "react";
import Header from '../components/header/Header'
import Footer from "../components/footer/Footer";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/EvChargingStationApp.webp";
import MobileAppScreen from "../components/mobile/MobileApp";
import SEO from "../common/SEO";

const MobileApp = () => {
    return (
        <>
            <SEO title='Find EV Charging Stations with Our App | MyEVPoint' description='Easily find nearby EV charging stations using the MyEVPoint app. Plan routes, check charger availability, and ensure a seamless electric vehicle journey.' 
            keywords="ev charging app, ev charging stations app, ev app, car charging station app, ev chargers app, ev station app, app for ev charging stations"/>
            <Header />
            <InnerPageBanner title='Ev Charging Station App' maxWidth='472px' innerPageBanner={aboutBanner} />
            <MobileAppScreen />
            <Footer />
        </>

    )
};

export default MobileApp;