import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import Product from "../components/product/Product";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/ourproducts.webp";

const Products = () => {
    return (
        <>
            <SEO title='EV Charging Stations - MyEVPoint Product Range' description='MyEVPoint offers 7.4 kW AC, 22 kW AC, 30 kW DC Fast, and 60 kW DC Fast EV charging stations. Find the ideal charger for your electric vehicle needs.'
            product={`
                {
                    "@context": "https://schema.org",
                    "@type": "ItemList",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "position": 1,
                            "item": {
                                "@type": "Product",
                                "name": "7.4 kW AC Charging Machine",
                                "image": [
                                    "https://www.myevpoint.in/static/media/EV%20Machine7.4.60c2af780f9ecd9580df.webp",
                                    "https://www.myevpoint.in/static/media/MyEVMachine7.4.6ac02f3fedc312a88bfd.webp"
                                ],
                                "description": "Buy Efficient and reliable 7.4 kW AC Charging station for electric vehicles, perfect for home and public use. Fast charging, easy installation, and user-friendly design",
                                "brand": {
                                    "@type": "Organization",
                                    "name": "MyEVPoint"
                                },
                                "offers": {
                                    "@type": "Offer",
                                    "priceCurrency": "INR",
                                    "price": "0"
                                }
                            }
                        },
                        {
                            "@type": "ListItem",
                            "position": 2,
                            "item": {
                                "@type": "Product",
                                "name": "22 kW AC Charging Machine",
                                "image": [
                                    "https://www.myevpoint.in/static/media/My%20EV%20Point%2022%20KW%20Machine.7a8e03bc2157d03e07ff.webp",
                                    "https://www.myevpoint.in/static/media/22%20KW%20Electric%20Vehicle%20Machine.192f1f0f38c352bda9c2.webp"
                                ],
                                "description": "22 kW AC Charging Machine for electric vehicles, ideal for commercial and high-traffic areas. Fast and efficient charging with user-friendly features.",
                                "brand": {
                                    "@type": "Organization",
                                    "name": "MyEVPoint"
                                },
                                "offers": {
                                    "@type": "Offer",
                                    "priceCurrency": "INR",
                                    "price": "0"
                                }
                            }
                        },
                        {
                            "@type": "ListItem",
                            "position": 3,
                            "item": {
                                "@type": "Product",
                                "name": "30 kW DC Fast EV Charging Machine",
                                "image": [
                                    "https://www.myevpoint.in/static/media/Untitled%20design%20(82).6e13d660900febdc5942.webp",
                                    "https://www.myevpoint.in/static/media/My%20EV%20Point%2030%20KW%20Charging%20Station%20Machine.fa3b3bb149c33d49fe78.webp"
                                ],
                                "description": "30 kW DC EV Fast Charging Machine for rapid electric vehicle charging. Ideal for busy locations, providing quick and reliable service.",
                                "brand": {
                                    "@type": "Organization",
                                    "name": "MyEVPoint"
                                },
                                "offers": {
                                    "@type": "Offer",
                                    "priceCurrency": "INR",
                                    "price": "0"
                                }
                            }
                        },
                        {
                            "@type": "ListItem",
                            "position": 4,
                            "item": {
                                "@type": "Product",
                                "name": "60 kW DC Fast EV Charging Machine",
                                "image": [
                                    "https://www.myevpoint.in/static/media/My%20EV%20Point%2060%20KW%20Machine.b54bd35ea5d363d2667d.webp",
                                    "https://www.myevpoint.in/static/media/Electric%20Vehicle%20Machine.ec2a1a227fa120c4d97c.webp"
                                ],
                                "description": "Ultra-fast 60 kW DC Charging Machine for electric vehicles, ensuring quick and efficient charging. Perfect for high-traffic areas, providing reliable performance.",
                                "brand": {
                                    "@type": "Organization",
                                    "name": "MyEVPoint"
                                },
                                "offers": {
                                    "@type": "Offer",
                                    "priceCurrency": "INR",
                                    "price": "0"
                                }
                            }
                        }
                    ]
                }
                `}
            />
            <Header />
            <InnerPageBanner title='Products' maxWidth='472px' innerPageBanner={aboutBanner} />
            <Product/>
            <Footer />
        </>
    )
}
export default Products;