import React from 'react'
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from '../screens/Home';
import Services from '../screens/Services';
import AppLayout from '../layout/AppLayout';
import AboutUs from '../screens/AboutUs';
import ServicesDetail from '../screens/ServicesDetail';
import OurBlogs from '../screens/Blog';
import SingleBlogDetail from '../screens/SingleBlog';
import OurGallery from '../screens/Gallery';
import ContactUs from "../screens/ContactUs"
import Terms from "../screens/Terms"
import Privacy from "../screens/Privacy"
import Refund from "../screens/Refund"
import Cancellation from "../screens/Cancellation"
import PdfComp from '../components/footer/PdfComp';
import NotFound from "../screens/NotFound"
import Location from "../screens/Location"
import Adminblog from '../screens/Adminblog';
import Admingallery from '../screens/Admingallery';
import Subscriber from '../screens/Subscriber';
import Login from '../screens/Adminlogin';
import AccountContext from "../utils/AccountContext"
import Sitemap from '../screens/SiteMap';
import MobileApp from '../screens/MobileApp';
import Faq from '../screens/Faq';
import Products from '../screens/Products';
import Franchise from '../screens/Franchisee';
import Partner from '../screens/Partners';
import Shippings from '../screens/Shipping';
import City from '../screens/Cities';
import Delhis from '../screens/Delhi';
import Oursolution from '../screens/Oursolutions';
import Video from '../screens/Videos';
import Image from '../screens/Images';
import Searchs from '../screens/SearchNew';
import ChargingGuide from '../screens/ChargingGuide';
import ContactList from '../screens/Contact';
import Careers from '../screens/Careers';
import Thankyou from '../screens/ThankYou';


function App() {
  const [login, setLogin] = useState(false);
  const [userData, setUserData] = useState(null);
  const data = {
    login,
    setLogin,
    userData,
    setUserData,
  };
  useEffect(() => {
    const sessionData = sessionStorage.getItem("userInfo");
    if (sessionData) {
      setLogin(true);
      setUserData(JSON.parse(sessionData));
    } else {
      setLogin(false);
      setUserData(null);
    }
  }, [setLogin, setUserData]);

// Component to remove trailing slashes
const RemoveTrailingSlashes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
      if (location.pathname !== "/" && location.pathname.endsWith("/")) {
          const fixedPath = location.pathname.replace(/\/+$/, ""); // Remove trailing slashes
          navigate(fixedPath, { replace: true });
      }
  }, [location, navigate]);
  return null; // This component does not render anything
};

  return (
    <AccountContext.Provider value={data}>
      <BrowserRouter>
        <RemoveTrailingSlashes /> {/* Fix URLs here */}
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route exact path="" element={<Home />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="blogs" element={<OurBlogs />} />
            <Route path="blogs/:type" element={<SingleBlogDetail />}/>
            <Route path="gallery" element={<OurGallery />}/>
            <Route path="gallery/images" element={<Image />} />
            <Route path="gallery/videos" element={<Video />}/>
            <Route path="images" element={<Image />}/>
            <Route path="services" element={<Services />}/>
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="services/:type" element={<ServicesDetail />}  />
            <Route path="ev-charging-app" element={<MobileApp />}  />
            <Route path="terms-and-conditions" element={<Terms />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="refund-policy" element={<Refund />} />
            <Route path="cancellation-policy" element={<Cancellation />} />
            <Route path="myEV_Point_brochure" element={<PdfComp />} />
            <Route path="admin-location" element={<Location />} />
            <Route path="admin-blog" element={<Adminblog />} />
            <Route path="admin-gallery" element={<Admingallery />} />
            <Route path="admin-subscriber" element={<Subscriber />} />
            <Route path="admin-login" element={<Login />} />
            <Route path="/admin-contact" element={<ContactList />} />
            <Route path="products" element={<Products />} />
            <Route path="franchise" element={<Franchise />} />
            <Route path="partner-with-us" element={<Partner />} />
            <Route path="shipping-delivery-policy" element={<Shippings />} />
            <Route path="states" element={<City />} />
            <Route path="/:types" element={<Delhis />} />
            <Route path="oursolution" element={<Oursolution />}  />
            <Route path="electric-vehicle-charging-station-in-delhi" element={<Delhis />}  />
            <Route path="sitemap.html" element={<Sitemap />} />
            <Route path="faqs" element={<Faq />} />
            <Route path="careers" element={<Careers />} />
            <Route path="search" element={<Searchs />} />
            <Route path="thank-you" element={<Thankyou />} />
            <Route path="vehicle-charging-guide" element={<ChargingGuide />}  />
            <Route path="*" element={<NotFound />}  />

            {/* <Route path="search" element={<SearchPage />} /> */}

            {/* {redirectiondata.map((data) => {
              return (
                <Route path={data?.pasturl} element={<Navigate to={data?.currenturl} replace />} />
              )}
            )} */}
          </Route>
        </Routes>
      </BrowserRouter>
    </AccountContext.Provider>
  );
};

export default App;

// const Checkpackage = () => {
//   const { type } = useParams();
//   const isInvalidPackageId = type !== "ac-charging-station" &&
//   type !== "dc-charging-station" && type !== "AC" && type !== "DC"
//   return !isInvalidPackageId ? <ServicesDetail /> : <NotFound />;
// };
const redirectiondata = [
  // {
  //   pasturl: "/blogs/Charging-for-Everyone",
  //   currenturl: "/blogs"
  // },
  // {
  //   pasturl: "/blog-detail/Driving-Change",
  //   currenturl: "/blogs"
  // },
  // {
  //   pasturl: "/blog-detail/The-Electric-Revolution",
  //   currenturl: "/blogs"
  // },
  // {
  //   pasturl: "/blogs/The-Electric-Revolution",
  //   currenturl: "/blogs"
  // },
  // {
  //   pasturl: "/blog-detail/Types-of-Charging-Stations",
  //   currenturl: "/blogs"
  // },
  // {
  //   pasturl: "/blogs/powering-drive-exploring-evs-charging-solutions",
  //   currenturl: "/blogs"
  // },
  // {
  //   pasturl: "/blogs/Revolutionizing-Charging",
  //   currenturl: "/blogs"
  // },
  // {
  //   pasturl: "/blogs/Driving-Change",
  //   currenturl: "/blogs"
  // },
  // {
  //   pasturl: "/services/AC",
  //   currenturl: "/services/ac-charging-station"
  // },
  // {
  //   pasturl: "/services/DC",
  //   currenturl: "/services/dc-charging-station"
  // },
  // {
  //   pasturl: "/cities",
  //   currenturl: "/states"
  // },
  // {
  //   pasturl: "/cities/electric-vehicle-charging-station-delhi",
  //   currenturl: "/electric-vehicle-charging-station-delhi"
  // },
  // {
  //   pasturl: "/cities/electric-vehicle-charging-station-punjab",
  //   currenturl: "/electric-vehicle-charging-station-punjab"
  // },
  // {
  //   pasturl: "/cities/electric-vehicle-charging-station-haryana",
  //   currenturl: "/electric-vehicle-charging-station-haryana"
  // },
  // {
  //   pasturl: "/cities/electric-vehicle-charging-station-himachal-pradesh",
  //   currenturl: "/electric-vehicle-charging-station-himachal-pradesh"
  // },
  // {
  //   pasturl: "/cities/electric-vehicle-charging-station-chandigarh",
  //   currenturl: "/electric-vehicle-charging-station-chandigarh"
  // },
  // {
  //   pasturl: "/cities/electric-vehicle-charging-station-mumbai",
  //   currenturl: "/electric-vehicle-charging-station-maharashtra"
  // },
  // {
  //   pasturl: "/cities/ev-charging-stations-up-uttar-pradesh",
  //   currenturl: "/ev-charging-stations-up-uttar-pradesh"
  // }
]