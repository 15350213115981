import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from "@mui/material/Switch";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import Delete from "../../shared/deletePopup";

const style = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '70%',
    maxHeight: '90%',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    py: 2,
    px: 4
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.white,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const AllGallery = ({ trigger, setTrigger }) => {
    const [open, setOpen] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [data, setData] = useState([]);
    const [detail, setDetail] = useState('')
    const [title, setTitle] = useState("");
    const [alt, setAlt] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        setTitle(detail?.title)
        setAlt(detail?.alt)
    }, [detail])

    const handleClose = () => {
        setOpen(false);
        setEditModal(false);
        setDetail("");
        setTitle("");
        setAlt("");
    }
    const handleView = (item) => {
        setOpen(true);
        setDetail(item)
    };

    const handleTitleChange = (e) => {
        const inputText = e.target.value;
        setTitle(inputText);
        setError("")
    };

    const handleAltChange = (e) => {
        const inputText = e.target.value;
        setAlt(inputText);
        setError("")
    };

    const handleSwitchChange = async (id) => {
        try {
            await axios.get(`${process.env.REACT_APP_URL}/user/updateStatus/${id}`)
        }
        catch (error) {
            console.log("Error updating. Try again later!", error)
        }
        fetchData();
    };

    const handleUpdate = async (id) => {
        if (!title || !alt) {
            setError("Fields must not be empty")
        } else {
            try {
                await axios.post(`${process.env.REACT_APP_URL}/user/editGallery/${id}`, {
                    title: title,
                    alt: alt
                });
                setEditModal(false)
                setTrigger(trigger + 1)
            } catch (error) {
                setError("Error updating. Try again later!")
            }
        }
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/user/getGallery`);
            setData(response?.data?.data);
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchData();
    }, [trigger]);

    return (
        <>
            <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px] mt-16">
                <TableContainer sx={{ maxWidth: 1000, minWidth: 1000 }}>
                    <Table aria-label="sticky table" className="w-150">
                        <TableHead >
                            <TableRow >
                                <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                    <strong>No.</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 150, minWidth: 150 }}>
                                    <strong>Title</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 300, minWidth: 300 }}>
                                    <strong>Description</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                    <strong>View</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                    <strong>Status</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                    <strong>Edit</strong>
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: 60, minWidth: 50 }}>
                                    <strong>Delete</strong>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {data.map((data, index) => {
                            return (
                                <TableBody>
                                    <StyledTableRow sx={{ borderBottomWidth: 1 }}>
                                        <StyledTableCell
                                            sx={{ maxWidth: 50, minWidth: 50 }}
                                        >
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.title}</StyledTableCell>
                                        <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.image}</StyledTableCell>
                                        <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                            <IconButton aria-label="edit" size="large" color="info" onClick={() => handleView(data)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <FormGroup>
                                                <FormControlLabel control={<Switch
                                                    checked={data.isActive}
                                                    onChange={() => handleSwitchChange(data?._id)}
                                                    inputProps={{ "aria-label": "controlled" }}
                                                />}
                                                />
                                            </FormGroup>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <IconButton onClick={() => {
                                                setDetail(data)
                                                setEditModal(true)
                                            }}
                                                aria-label="edit"
                                                size="medium">
                                                <FaEdit />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Delete
                                                id={data?._id}
                                                trigger={trigger}
                                                setTrigger={setTrigger}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )
                        })}
                    </Table>
                </TableContainer>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description">
                <div className='dashboard'>
                    <Box sx={style}>
                        <main className="mt-2">
                            <div className="mb-4 md:mb-0 w-full mx-auto relative" onClick={handleClose}>
                                <div className="text-right">
                                    <IconButton aria-label="edit" color="inherit"  >
                                        <CloseIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </div>
                                <div className="px-4 lg:px-0">
                                    <p className="text-3xl font-semibold text-gray-800 leading-tight text-center">
                                        {detail?.title}
                                    </p>
                                    {detail?.alt &&
                                        <p className="text-center">Alt Tag : {detail?.alt}</p>
                                    }
                                </div>
                                {detail?.image &&
                                    <img src={detail?.image} className="h-80 object-cover lg:rounded mx-auto mt-5" alt="Ev Charging Machine" />
                                }
                            </div>
                            <div className="flex flex-col lg:flex-row lg:space-x-12">
                                <div className="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-full">
                                    <p className="pb-6">{detail?.description}</p>
                                </div>
                            </div>
                        </main>
                    </Box>
                </div>
            </Modal>
            <Modal
                keepMounted
                open={editModal}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md">
                        <div className="text-right">
                            <IconButton aria-label="edit" color="inherit" onClick={handleClose} >
                                <CloseIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        </div>
                        <h2 className="text-xl font-semibold mb-4">Edit Details</h2>
                        {
                            error &&
                            <p className="text-[#e50914]">{error}</p>
                        }
                        <div className="space-y-2">
                            <div>
                                <label className="block text-sm font-medium">Title</label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                                    name="title"
                                    type="text"
                                    value={title}
                                    onChange={handleTitleChange}
                                    placeholder="Enter Title"
                                    maxLength={30}
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Alt Tag</label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                                    name="alt"
                                    type="text"
                                    value={alt}
                                    onChange={handleAltChange}
                                    placeholder="Enter Alt"
                                    maxLength={60}
                                />
                            </div>
                        </div>
                        <button className="w-full bg-[#0B7132] text-white py-2 rounded-md mt-4" onClick={() => handleUpdate(detail._id)}>
                            Update
                        </button>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default AllGallery;