import React, { useState } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import axios from 'axios';

const Delete = ({ id, trigger, setTrigger }) => {

    const [open, setOpen] = useState(false);
    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_URL}/user/gallery/${id}`);
            setTrigger(trigger + 1)
            setOpen(false)
        } catch (error) {
            console.log("Error deleting. Try again later!")
        }
    }

    const handleNoDelete = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton onClick={() => setOpen(true)}
                aria-label="delete"
                size="medium">
                <MdDelete />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleNoDelete}
                aria-labelledby="delete-confirmation-dialog-title"
                aria-describedby="delete-confirmation-dialog-description"
            >
                <DialogTitle id="delete-confirmation-dialog-title">Delete Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this image?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} variant="contained" style={{ backgroundColor: "#0B7132", color: "#ffffff" }}>
                        Yes
                    </Button>
                    <Button onClick={handleNoDelete} variant="contained" style={{ backgroundColor: "#333333", color: "#ffffff" }}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Delete;