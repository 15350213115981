import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SEO = ({ title = "", description = "", keywords = "", schema = "", business = "", product = "", 
  organization = "", faqs = "", noIndex = false }) => {
  const { pathname } = useLocation();
  return (
    <Helmet>
      <title>{title || "myEVpoint"}</title>
      <meta name="description" content={description || "Best Electric Vehicle (EV) Charging Station"} />
      <meta property="og:title" content={title || "myEVpoint"} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://www.myevpoint.in${pathname}`} />
      <meta name="robots" content="index, follow" />
      {noIndex && ( <meta name="robots" content="noindex, nofollow" /> )}
      <link rel="canonical" href={`https://www.myevpoint.in${pathname}`} />
      {keywords && <meta name="keywords" content={keywords} />}
      {schema && (
        <script defer type="application/ld+json">
          {schema}
        </script>
      )}
      {organization && (
        <script defer type="application/ld+json">
          {organization}
        </script>
      )}
      {business && (
        <script defer type="application/ld+json">
          {business}
        </script>
      )}
      {product && (
        <script defer type="application/ld+json">
          {product}
        </script>
      )}
      {faqs && (
        <script defer type="application/ld+json">
          {faqs}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
