import React, { useState, useRef, useEffect, useContext } from 'react'
import "./header.css";
import "../../assets/css/style.css"
import ImageIcons from '../../common/ImageIcons'
import { FaFacebookF, FaTwitter, FaYoutube, FaPinterest, FaLinkedinIn, FaRegEnvelope } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { MdPhone, MdSearchOff } from "react-icons/md";
import { NavLink, Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { windowScroll } from "../../helpers/ScrollToTop";
import { CopyToClipboard } from "../../helpers/copyToClibboard";
import { FaRegCopy, FaXTwitter } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import axios from 'axios';
import servicesData from "../../data/services.json"
import { UIContext } from '../../context';
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const handleLogoClick = () => {
        if (pathname === '/') {
            handleCloseDrawer();
        }
    };
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
        setOpen1(false)
    };
    const handleClickgallery = () => {
        setOpen1(!open1);
        setOpen(false)
    };
    const handleCloseDrawer = () => {
        setState({ ...state, left: false });
    };
    const handleCloseDrawer1 = () => {
        setState({ ...state, left: false });
    };
    useEffect(() => {
        // Automatically open the drawer if the pathname matches any sub-service route
        if (pathname.startsWith('/services/')) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [pathname]);
    useEffect(() => {
        // Automatically open the drawer if the pathname matches any sub-service route
        if (pathname.startsWith('/gallery/')) {
            setOpen1(true);
        } else {
            setOpen1(false);
        }
    }, [pathname]);

    const list = (anchor) => (
            <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }} role="presentation">
            <Divider />
            <NavLink to="/" onClick={handleLogoClick}>
                <img src={ImageIcons.myEV_logo} alt='myEVpoint Logo' className='lg:max-w-[160px] max-lg:max-w-[110px] cursor-pointer py-4 pl-3' /></NavLink>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader">
                <NavLink to="/about-us" className={({ isActive }) => isActive ? "text-[#fff]" : "hover:text-[#0B7132]"} onClick={windowScroll}>
                    <ListItem disablePadding className={`${pathname === '/about-us' && "bg-[#0B7132] text-white"}`}>
                        <ListItemButton>
                            <ListItemText primary="About Us" />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <Divider />
                <NavLink to="/products" className={({ isActive }) => isActive ? "text-[#fff]" : "hover:text-[#0B7132]"} onClick={windowScroll}>
                    <ListItem disablePadding className={`${pathname === '/products' && "bg-[#0B7132] text-white"}`}>
                        <ListItemButton>
                            <ListItemText primary="Products" />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <Divider />
                <div className={`${pathname === '/services' ? "text-[#fff]" : "text-[#000] hover:text-[#0B7132]"} `}>
                    <ListItem disablePadding
                        className={`${(pathname === '/services' || pathname === '/services/ac-charging-station' || pathname === '/services/dc-charging-station') && "bg-[#0B7132] "} `}>
                        <ListItemButton onClick={windowScroll}>
                            <Link to="/services" style={{ flexGrow: 1 }}>
                                <ListItemText className={`${(pathname === '/services' || pathname === '/services/ac-charging-station' || pathname === '/services/dc-charging-station') ? "text-white" : "text-black"}`} primary="Our Service"/>
                            </Link>
                            <div onClick={(e) => { e.stopPropagation(); handleClick(); }} className='absolute right-[10px]'>
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItemButton>
                    </ListItem>
                </div>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem disablePadding className={`${pathname === '/services/ac-charging-station' ? "bg-[#000] " : "bg-[#000] text-white"}`}>
                            <NavLink to="/services/ac-charging-station" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={(e) => { windowScroll(); handleCloseDrawer(); }}>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary="AC Charger Service" />
                                </ListItemButton>
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem disablePadding className={`${pathname === '/services/dc-charging-station' ? "bg-[#000]" : "bg-[#000] text-white"}`}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <NavLink to="/services/dc-charging-station" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={(e) => { windowScroll(); handleCloseDrawer(); }}>
                                    <ListItemText primary="DC Charger Service" />
                                </NavLink>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Collapse>

                <Divider />
                <NavLink to="/vehicle-charging-guide" className={({ isActive }) => isActive ? "text-[#fff]" : "hover:text-[#0B7132]"} onClick={windowScroll}>
                    <ListItem disablePadding className={`${pathname === '/vehicle-charging-guide' && "bg-[#0B7132] "} `}>
                        <ListItemButton>
                            <ListItemText primary="How To Use" />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <Divider />
                <div className={`${pathname === '/gallery' ? "text-[#fff]" : "text-[#000] hover:text-[#0B7132]"} `}>
                    <ListItem disablePadding className={`${(pathname === '/gallery' || pathname === '/gallery/images' || pathname === '/gallery/videos') && "bg-[#0B7132] "} `}>
                        <ListItemButton onClick={windowScroll}>
                            <Link to="/gallery" style={{ flexGrow: 1 }}>
                                <ListItemText className={`${(pathname === '/gallery' || pathname === '/gallery/images' || pathname === '/gallery/videos') ? "text-white" : "text-black"}`} primary="Gallery"/>
                            </Link>
                            <div onClick={(e) => { e.stopPropagation(); handleClickgallery(); }} className='absolute right-[10px]'>
                                {open1 ? <ExpandLess /> : <ExpandMore />}
                            </div>
                        </ListItemButton>
                    </ListItem>
                </div>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem disablePadding className={`${pathname === '/gallery/images' ? "bg-[#000] text-white" : "bg-[#000] text-white"}`}>
                            <NavLink to="/gallery/images" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={(e) => { windowScroll(); handleCloseDrawer1(); }}>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary="Images" />
                                </ListItemButton>
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem disablePadding className={`${pathname === '/gallery/videos' ? "bg-[#000] text-white" : "bg-[#000] text-white"}`}>
                            <ListItemButton sx={{ pl: 4 }}>
                                <NavLink to="/gallery/videos" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={(e) => { windowScroll(); handleCloseDrawer1(); }}>
                                    <ListItemText primary="Videos" />
                                </NavLink>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Collapse>
                <Divider />
                <NavLink to="/contact-us" className={({ isActive }) => isActive ? "text-[#fff]" : "hover:text-[#0B7132]"} onClick={windowScroll}>
                    <ListItem disablePadding className={`${pathname === '/contact-us' && "bg-[#0B7132] text-white"}`}>
                        <ListItemButton>
                            <ListItemText primary="Contact Us" />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <div className='px-4 mt-6'>
                    <div className='button-getstarted flex mb-5'>
                        <NavLink to="/ev-charging-app" className="font-size-16 text-white bg-[#0B7132] hover:bg-black rounded-full px-6 py-3 inline-flex items-center" onClick={windowScroll}>
                            Get App
                        </NavLink>
                    </div>
                    {/* contact email and phone */}
                    <div className="text-[#000] max-lg:mb-5">
                        <div className='flex items-center mb-4'>
                            <FaRegEnvelope className='h-5 w-6 cursor-pointer' />
                            <span onClick={openMail} className="ml-1 font-size-16 text-[#000] cursor-pointer">{email}</span>
                        </div>
                        <div className='flex items-center'>
                            <MdPhone className='h-5 w-6 cursor-pointer' />
                            <span onClick={callPhoneNumber} className="ml-1 font-size-16 text-[#000] cursor-pointer">{phoneNumber}</span>
                        </div>
                    </div>
                    {/* Social media links */}
                    <div className="flex flex-wrap items-center lg:gap-4 text-[#000] max-lg:gap-2 max-lg:mb-2">
                        <Link className='flex w-[35px] h-[35px] text-white bg-[#000] rounded-full items-center justify-center cursor-pointer hover:text-[#0B7132]' to='https://www.facebook.com/myevpoint' target="_blank"><FaFacebookF className='h-4 w-5' /></Link>
                        <Link className='flex w-[35px] h-[35px] text-white bg-[#000] rounded-full items-center justify-center cursor-pointer hover:text-[#0B7132]' to='https://www.instagram.com/myevpoint/' target="_blank"><GrInstagram className='h-4 w-5' /></Link>
                        <Link className='flex w-[35px] h-[35px] text-white bg-[#000] rounded-full items-center justify-center cursor-pointer hover:text-[#0B7132]' to='https://twitter.com/myevpoint' target="_blank"><FaTwitter className='h-4 w-5' /></Link>
                        <Link className='flex w-[35px] h-[35px] text-white bg-[#000] rounded-full items-center justify-center cursor-pointer hover:text-[#0B7132]' to='https://in.pinterest.com/myevpoint/' target="_blank"><FaPinterest className='h-4 w-5' /></Link>
                        <Link className='flex w-[35px] h-[35px] text-white bg-[#000] rounded-full items-center justify-center cursor-pointer hover:text-[#0B7132]' to='https://www.youtube.com/channel/UCI3qj7D2eG-hqQ-HP948QWw' target="_blank"><FaYoutube className='h-4 w-5' /></Link>
                        <Link className='flex w-[35px] h-[35px] text-white bg-[#000] rounded-full items-center justify-center cursor-pointer hover:text-[#0B7132]' to='https://www.linkedin.com/company/my-ev-point/' target="_blank"><FaLinkedinIn className='h-4 w-5' /></Link>
                    </div>
                </div>
            </List>
        </Box >
    )

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpen3, setDropdownOpen3] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(s => !s);
    };
    const toggleDropdown3 = () => {
        setDropdownOpen3(s => !s);
    };
    useEffect(() => {
    }, [dropdownOpen]);
    useEffect(() => {
    }, [dropdownOpen1]);
    useEffect(() => {
    }, [dropdownOpen2]);
    useEffect(() => {
    }, [dropdownOpen3]);
    const closeDropdown = () => {
        setDropdownOpen(false)
        windowScroll()
    }
    const closeDropdown3 = () => {
        setDropdownOpen3(false)
        windowScroll()
    }
    const phoneNumber = '+91 9592-595975';
    const phoneLink = `tel:${phoneNumber}`;
    const callPhoneNumber = () => {
        window.location.href = phoneLink;
    };
    const email = 'info@myevpoint.in';
    const mailtoLink = `mailto:${email}`;
    const openMail = () => {
        window.open(mailtoLink);
    };
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isIconClick = event.target.closest('.exclude-icon-class');
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !isIconClick) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isIconClick = event.target.closest('.exclude-icon-class');
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !isIconClick) {
                setDropdownOpen1(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isIconClick = event.target.closest('.exclude-icon-class');
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !isIconClick) {
                setDropdownOpen2(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isIconClick = event.target.closest('.exclude-icon-class');
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !isIconClick) {
                setDropdownOpen3(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.pageYOffset > (elTopOffset + elHeight)) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };
    const handleMouseEnter = () => {
        setDropdownOpen(true);
        setDropdownOpen3(false);

    };
    const handleMouseEnter3 = () => {
        setDropdownOpen(false);
        setDropdownOpen3(true);
    };
    const handleMouseLeave = () => {
        setDropdownOpen(false);
        setDropdownOpen3(false)
    };
    const handleMouseLeave3 = () => {
        setDropdownOpen3(false);
    };
    useEffect(() => {
        var header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height);
        };
        window.addEventListener('scroll', handleScrollEvent);
        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);
    const mail = "info@myevpoint.in";
    const [searchTerm, setSearchKey] = useState("");
    const [blogData, setBlogData] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const { setSearchTerm, setSearchedData } = useContext(UIContext);
    const fetchBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}/user/getBlogs`);
        setBlogData(response.data.data)
    }
    useEffect(() => {
        fetchBlogs()
    }, [])
    useEffect(() => {
        const filteredResult =
            Array.isArray(blogData) && blogData?.length > 0
                ? blogData?.filter((blogItem) =>
                    (blogItem.isActive === true) &&
                    (blogItem.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        blogItem.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        blogItem.author.toLowerCase().includes(searchTerm.toLowerCase()))
                )
                : [];
        setFilteredBlogs(filteredResult);
    }, [searchTerm, blogData]);

    useEffect(() => {
        const filteredServiceResult =
            Array.isArray(servicesData) && servicesData?.length > 0
                ? servicesData?.filter((serviceItem) => {
                    const searchTermLower = searchTerm.toLowerCase();
                    const additionalInfoMatches = serviceItem?.additionalInfo.some(
                        (info) =>
                            info.title.toLowerCase().includes(searchTermLower) ||
                            info.description.toLowerCase().includes(searchTermLower)
                    );
                    const slowFeaturesMatches = serviceItem?.slowFeatures?.some(
                        (feature) => feature.text.toLowerCase().includes(searchTermLower)
                    );
                    const fastFeaturesMatches = serviceItem?.fastFeatures?.some(
                        (feature) => feature.text.toLowerCase().includes(searchTermLower)
                    );
                    const singleFeaturesMatches = serviceItem?.singleFeatures?.some(
                        (feature) => feature.text.toLowerCase().includes(searchTermLower)
                    ); const dualFeaturesMatches = serviceItem?.fastFeatures?.some(
                        (feature) => feature.text.toLowerCase().includes(searchTermLower)
                    );

                    return (
                        serviceItem?.short.toLowerCase().includes(searchTermLower) ||
                        serviceItem?.type.toLowerCase().includes(searchTermLower) ||
                        serviceItem?.title.toLowerCase().includes(searchTermLower) ||
                        serviceItem?.desc1.toLowerCase().includes(searchTermLower) ||
                        serviceItem?.summary.toLowerCase().includes(searchTermLower) ||
                        additionalInfoMatches ||
                        slowFeaturesMatches ||
                        fastFeaturesMatches ||
                        singleFeaturesMatches ||
                        dualFeaturesMatches
                    );
                })
                : [];
        setFilteredServices(filteredServiceResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, servicesData]);
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setSearchedData({ blogs: filteredBlogs, services: filteredServices });
            setSearchTerm(searchTerm);
            setFilteredServices([]);
            setSearchKey("");
            navigate("/search", {
                state: {
                    searchTerm: searchTerm
                }
            });
        }
    };
    const handleSearchSubmit = () => {
        if (!searchTerm.trim()) {
            // Do not perform search if searchTerm is empty
            return;
        }
        setSearchedData({ blogs: filteredBlogs, services: filteredServices });
        setSearchTerm(searchTerm);
        setFilteredServices([]);
        setSearchKey("");
        navigate("/search", {
            state: {
                searchTerm: searchTerm
            }
        });
    };
    const searchDropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchDropdownRef.current && !searchDropdownRef.current.contains(event.target)) {
                setSearchKey('');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchDropdownRef]);
    function resetHandler() {
        setSearchKey("");
        setFilteredServices([]);
    }
    return (
        <>
            <header onMouseEnter={handleMouseLeave} onMouseLeave={handleMouseLeave}>
                <div className="w-full">
                    {/* top bar info */}
                    <div className="w-full bg-black">
                        <div className="container top-bar-header flex mx-auto justify-between items-center max-lg:flex-col px-2 lg:py-2 max-lg:py-2">
                            {/* contact email and phone */}
                            <div className="flex text-[#fff] max-lg:mb-0">
                                <div className='flex items-center max-lg:hidden'>
                                    <MdPhone className='h-5 w-6 cursor-pointer' />
                                    <span onClick={callPhoneNumber} className="ml-2 font-size-16 text-[#fff] cursor-pointer">{phoneNumber}</span>
                                </div>
                                <div className='flex items-center max-lg:hidden'>
                                    <span className='clr-ddd mx-4'>|</span>
                                </div>
                                <div onClick={() => CopyToClipboard(mail)} className='flex items-center max-lg:hidden'>
                                    <FaRegEnvelope className='h-5 w-6 cursor-pointer' />
                                    <span className="ml-2 font-size-16 text-[#fff] cursor-pointer">Email Us &nbsp;</span>
                                    <FaRegCopy className='h-5 w-6 cursor-pointer' />
                                </div>
                                {/* search form */}
                                <div className='flex items-center md:ml-4'>
                                    <form>
                                        <div className="flex">
                                            <div className="relative w-full" ref={searchDropdownRef}>
                                                <input type="text" id="search-dropdown" className="focus:outline-none bg-[#fff] text-[#000] md:h-[40px] max-md:h-[30px] rounded-full block w-full md:px-5 max-md:px-3 md:py-[8px] max-md:py-[6px] pe-[40px]" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchKey(e.target.value)} onKeyDown={handleKeyPress} />
                                                {searchTerm && (
                                                    <div className='absolute z-20 search-fill-info bg-[#000]'>
                                                        {filteredBlogs.length === 0 && filteredServices.length === 0 ? (
                                                            <ul>
                                                                <li>
                                                                    <div className='text-white px-2 py-2 border-b flex w-56 justify-center'>
                                                                        <MdSearchOff className='h-5 w-6 cursor-pointer' />
                                                                        Not Found
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        ) : (
                                                            <ul>
                                                                {filteredBlogs?.map((blogItem) => (
                                                                    <li key={blogItem?._id} className='w-56 text-center'>
                                                                        <Link onClick={resetHandler} className='block text-white px-2 py-2 border-b hover:text-[#0B7132]' to={`/blogs/${blogItem?.handle?.replace(/\s+/g, "-").toLowerCase()}`}>
                                                                            {blogItem?.title.slice(0, 20)}...
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                                {filteredServices?.map((serviceItem) => (
                                                                    <li key={serviceItem?._id} className='w-56 text-center'>
                                                                        <Link onClick={resetHandler} className='block text-white px-2 py-2 border-b hover:text-[#0B7132]' to={`/services/${serviceItem?.type?.replace(/\s+/g, "-").toLowerCase()}`}>
                                                                            {serviceItem?.title.slice(0, 20)}...
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                )}
                                                <div type="submit" className="absolute  md:h-[40px] max-md:h-[30px] md:min-w-[40px] max-md:min-w-[30px] py-[8px] right-0 top-0 flex items-center justify-center text-white bg-[#0B7132] rounded-full">
                                                    <IoSearch className='md:text-[18px] max-md:text-[16px]' onClick={handleSearchSubmit} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {/* search form end */}
                            </div>
                            {/* Social media links */}
                            <div className="flex items-center lg:gap-4 text-[#fff] max-lg:gap-3 max-lg:mb-2 max-lg:hidden">
                                <Link to='https://www.facebook.com/myevpoint' target="_blank"><FaFacebookF className='h-5 w-6 cursor-pointer hover:text-[#0B7132]' /></Link>
                                <Link to='https://www.instagram.com/myevpoint/' target="_blank"><GrInstagram className='h-5 w-6 cursor-pointer hover:text-[#0B7132]' /></Link>
                                <Link to='https://twitter.com/myevpoint' target="_blank"><FaXTwitter className='h-5 w-6 cursor-pointer hover:text-[#0B7132]' /></Link>
                                <Link to='https://in.pinterest.com/myevpoint/' target="_blank"><FaPinterest className='h-5 w-6 cursor-pointer hover:text-[#0B7132]' /></Link>
                                <Link to='https://www.youtube.com/channel/UCI3qj7D2eG-hqQ-HP948QWw' target="_blank"><FaYoutube className='h-5 w-6 cursor-pointer hover:text-[#0B7132]' /></Link>
                                <Link to='https://www.linkedin.com/company/my-ev-point/' target="_blank"><FaLinkedinIn className='h-5 w-6  cursor-pointer hover:text-[#0B7132]' /></Link>
                            </div>
                        </div>
                    </div>
                    {/* Header Navbar */}
                    <div className={`${sticky.isSticky ? 'sticky' : ''} ${sticky.isSticky ? 'z-50' : ''}`} ref={headerRef}>
                        <div className="w-full bg-white header-navbar-info shadow-md">
                            <div className='container mx-auto'>
                                <div className='flex mx-auto justify-between gap-4 py-2'>
                                    {/* Navbar Logo */}
                                    <div className='site-logo-info'>
                                        <NavLink to="/" onClick={windowScroll}>
                                            <img src={ImageIcons.myEV_logo} alt='My Ev Point' className='lg:max-w-[160px] max-lg:max-w-[110px] cursor-pointer' />
                                        </NavLink>
                                    </div>
                                    <div className='flex my-auto'>
                                        {/* Navbar menu */}
                                        <div className="hidden lg:flex items-center">
                                            <ul className='primary-menu-info flex list-none'>
                                                <li className='menu-item-info'>
                                                    <NavLink to="/about-us" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={windowScroll}>About Us</NavLink>
                                                </li>
                                                <li className='menu-item-info'>
                                                    <NavLink to="/products" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={windowScroll}>Products</NavLink>
                                                </li>
                                                <li className='menu-item-info' onMouseEnter={handleMouseEnter}>
                                                    <NavLink to="/services" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={windowScroll}>
                                                        Our Service
                                                    </NavLink>
                                                    <svg onClick={toggleDropdown} fill="currentColor" viewBox="0 0 20 20" className="icon-down-menu inline ml-1 transition-transform duration-200 transform pointer exclude-icon-class"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" color='#000' cursor='pointer'></path></svg>
                                                    {dropdownOpen &&
                                                        <div className='submenu-info' onMouseLeave={handleMouseLeave}>
                                                            <div ref={dropdownRef} className='submenu-links bg-white'>
                                                                <ul className='submenu-link-menu'>
                                                                    <li className={`${pathname === '/services/ac-charging-station' ? "link-menu active" : "link-menu"}`}>
                                                                        <NavLink to="/services/ac-charging-station" className={({ isActive }) => isActive ? "text-[#fff] bg-[#0B7132]" : "hover:text-[#fff] hover:bg-[#0B7132]"} onClick={closeDropdown}>AC Charger Service</NavLink>
                                                                    </li>
                                                                    <li className={`${pathname === '/services/dc-charging-station' ? "link-menu active" : "link-menu"}`}>
                                                                        <NavLink to="/services/dc-charging-station" className={({ isActive }) => isActive ? "text-[#fff] bg-[#0B7132]" : "hover:text-[#fff]  hover:bg-[#0B7132]"} onClick={closeDropdown}>DC Charger Service</NavLink>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                                <li className='menu-item-info'>
                                                    <NavLink to="/vehicle-charging-guide" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={windowScroll}>How To Use</NavLink>
                                                </li>
                                                <li className='menu-item-info' onMouseEnter={handleMouseEnter3}>
                                                    <NavLink to="/gallery" className={({ isActive }) => isActive ? "text-[#0B7132]" : "hover:text-[#0B7132]"} onClick={windowScroll}>
                                                        Gallery
                                                    </NavLink>
                                                    <svg onClick={toggleDropdown3} fill="currentColor" viewBox="0 0 20 20" className="icon-down-menu inline ml-1 transition-transform duration-200 transform pointer exclude-icon-class"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" color='#000' cursor='pointer'></path></svg>
                                                    {dropdownOpen3 &&
                                                        <div className='submenu-info' onMouseLeave={handleMouseLeave3}>
                                                            <div ref={dropdownRef} className='submenu-links bg-white'>
                                                                <ul className='submenu-link-menu'>
                                                                    <li className={`${pathname === '/gallery/images' ? "link-menu active" : "link-menu"}`}>
                                                                        <NavLink to="/gallery/images" className={({ isActive }) => isActive ? "text-[#fff]" : "hover:text-[#fff] hover:bg-[#0B7132]"} onClick={closeDropdown3}>Images</NavLink>
                                                                    </li>
                                                                    <li className={`${pathname === '/gallery/videos' ? "link-menu active" : "link-menu"}`}>
                                                                        <NavLink to="/gallery/videos" className={({ isActive }) => isActive ? "text-[#fff]" : "hover:text-[#fff]  hover:bg-[#0B7132]"} onClick={closeDropdown3}>Videos</NavLink>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                                <li className='menu-item-info'>
                                                    <NavLink to="/contact-us" className={({ isActive }) => isActive ? "text-[#0B7132]" : "text-[#000] hover:text-[#0B7132]"} onClick={windowScroll}>Contact Us</NavLink>
                                                </li>
                                            </ul>
                                            <div className='button-getstarted flex text-center '>
                                                <NavLink to="/ev-charging-app" className="font-size-16 text-white bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] rounded-full ml-5 px-6 py-3 inline-flex items-center" onClick={windowScroll}>
                                                    Get App
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="lg:hidden ml-10 mt-1" onClick={toggleDrawer('left', true)}>
                                                <button type="button" className="block pointer text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none">
                                                    <svg className="h-6 w-6 fill-current " viewBox="0 0 24 24">
                                                        <path className="hidden" d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z" />
                                                        <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <Drawer className='block lg:hidden' anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>{list('left', handleCloseDrawer)}</Drawer>
                                        </div>
                                        {/* Mobile Navbar menu end */}
                                    </div>
                                    {/* Navbar menu end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;