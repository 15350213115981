import React from "react";
import ImageIcons from "../../common/ImageIcons";
import bgimgservices from "../../assets/Images/bg-img-services.webp";
import bgimgmachine from "../../assets/Images/bg-img-machine.webp";

const Features = () => {
  return (
    <>
      {/* Service Section Start */}
      <div className="features-section md:py-20 max-md:py-[50px] relative bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url(${bgimgservices})` }} >
        <div className="container mx-auto px-2">
          <h3 className="h2 text-center md:pb-12 max-md:pb-8 text-[#fff]">Excellent And Best Services</h3>
          <div className="features-listing-info">
            <div className="flex md:items-center gap-5 max-md:flex-col">
              <div className="md:w-1/3 max-md:order-1">
                <div className="left-icon-box-info mb-8">
                  <div className="left-icon-h4-info flex items-center gap-2 mb-3">
                    <div className="text-xl font-medium flex items-center rounded-full justify-center text-[#000] bg-[#fff] min-w-[44px] h-11">01</div>
                    <h4 className="text-[#fff]">Maximize Your Earnings</h4>
                  </div>
                  <p className="text-[#fff]">Earn up to ₹ 4 /unit</p>
                </div>
                <div className="left-icon-box-info mb-8">
                  <div className="left-icon-h4-info flex items-center gap-2 mb-3">
                    <div className="text-xl font-medium flex items-center rounded-full justify-center text-[#000] bg-[#fff] min-w-[44px] h-11">02</div>
                    <h4 className="text-[#fff]">Top Income Opportunities</h4>
                  </div>
                  <p className="text-[#fff]">The Ultimate Passive Income Opportunity Awaits You!</p>
                </div>
                <div className="left-icon-box-info md:mb-8 max-md:mb-[10px]">
                  <div className="left-icon-h4-info flex items-center gap-2 mb-3">
                    <div className="text-xl font-medium flex items-center rounded-full justify-center text-[#000] bg-[#fff] min-w-[44px] h-11">03</div>
                    <h4 className="text-[#fff]">Effortless Earnings</h4>
                  </div>
                  <p className="text-[#fff]">Automated Monthly Payouts and Monitoring</p>
                </div>
              </div>
              <div className="md:w-1/3 max-md:order-3">
                <div className="mid-img-info text-center bg-no-repeat bg-contain bg-center" style={{ backgroundImage: `url(${bgimgmachine})` }}>
                  <img src={ImageIcons.EVCharging} alt="EV Charging Station" title="Electric Car Charging Stations"/>
                </div>
              </div>
              <div className="md:w-1/3 max-md:order-2">
                <div className="left-icon-box-info mb-8">
                  <div className="left-icon-h4-info flex items-center gap-2 mb-3">
                    <div className="text-xl font-medium flex items-center rounded-full justify-center text-[#000] bg-[#fff] min-w-[44px] h-11">04</div>
                    <h4 className="text-[#fff]">Cost Reduction Strategies</h4>
                  </div>
                  <p className="text-[#fff]">Lower Maintenance cost</p>
                </div>
                <div className="left-icon-box-info mb-8">
                  <div className="left-icon-h4-info flex items-center gap-2 mb-3">
                    <div className="text-xl font-medium flex items-center rounded-full justify-center text-[#000] bg-[#fff] min-w-[44px] h-11">05</div>
                    <h4 className="text-[#fff]">Eco-Friendly Finances</h4>
                  </div>
                  <p className="text-[#fff]">Going Green and Growing Wealth.</p>
                </div>
                <div className="left-icon-box-info mb-8">
                  <div className="left-icon-h4-info flex items-center gap-2 mb-3">
                    <div className="text-xl font-medium flex items-center rounded-full justify-center text-[#000] bg-[#fff] min-w-[44px] h-11">06</div>
                    <h4 className="text-[#fff]">Smooth Sailing</h4>
                  </div>
                  <p className="text-[#fff]">No operational headaches, just smooth sailing.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service Section End */}
    </>
  );
};

export default Features;
