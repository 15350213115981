import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import ServicesList from '../components/services/ServicesList'
import Strategy from '../components/services/Strategy'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/OurServiceAC&DCCurrent.webp";
import ContactInfo from '../components/contact_info/ContactInfo';
import SEO from '../common/SEO'

const Services = () => {
    return (
        <>
            <SEO title='AC/DC EV Chargers for Cars | MyEVPoint Services' description='MyEVPoint offers efficient AC and DC EV chargers for cars. Reliable charging solutions to meet your electric vehicle requirements.' 
            keywords='ac dc ev charger, ac dc charger for car, ac dc car charger'/>
            <Header />
            <InnerPageBanner title='Our Services' maxWidth='472px' innerPageBanner={aboutBanner} />
            <ServicesList />
            <Strategy />
            <ContactInfo />
            <Footer />
        </>
    )
}

export default Services;
