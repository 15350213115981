import React from "react";
import ImageIcons from '../../common/ImageIcons'
import phonenearby from '../../assets/Images/phonenearby.webp';
import mapdottedinfo from '../../assets/Images/mapdottedinfo.webp';
import { FaApple, FaGooglePlay } from "react-icons/fa";
import { Link } from 'react-router-dom'

const MobileApp = () => {
    return (
        <>
            <section className="app-section w-full md:py-20 max-md:py-10 ">
                <div className='container mx-auto px-2'>
                    <div className='mt-10'>
                        <div className='flex gap-4 max-md:flex-col items-center'>
                            <div className='md:w-1/2 mb-5 text-center bg-contain bg-center bg-no-repeat max-md:w-full' style={{ backgroundImage: `url('${mapdottedinfo}')` }}>
                                <img className="m-auto mt-[-60px]" alt="app for ev charging stations" title="app for ev charging stations" src={phonenearby} />
                            </div>
                            <div className='md:w-1/2 py-5 px-3'>
                                <h2 className='mb-3'>myEVPoint Charging Station Mobile App</h2>
                                <p className='mb-3'>Experience the world of most convenient EV charging with our myEV point Charging App!</p>
                                <p className='mb-8'>We provide a vast network of charging stations which are easily accessible through our unparalleled mobile application.</p>
                                <div className="button-hero-info flex gap-4 max-md:flex-col">
                                    <Link to="https://apps.apple.com/in/app/myev-point/id6475722779" target="blank" > <button className="cursor-pointer bg-[#0B7132] text-[#fff] hover:text-[#fff] hover:bg-[#000] font-semibold md:py-3 max-md:py-3 md:px-5 max-md:px-4 rounded-lg inline-flex items-center justify-center duration-[400ms,700ms]">
                                        <span>
                                            <FaApple className='md:h-7 max-md:h-6 md:w-7 max-md:w-6 me-1' />
                                        </span>
                                        <span>APP STORE</span>
                                    </button>
                                    </Link>
                                    <Link to="https://play.google.com/store/apps/details?id=com.vpbroadband.evcharging" target="blank" >

                                        <button className="cursor-pointer bg-[#0B7132] text-[#fff] hover:text-[#fff] hover:bg-[#000] font-semibold md:py-3 max-md:py-3 md:px-5 max-md:px-4 rounded-lg inline-flex items-center justify-center duration-[400ms,700ms]">
                                            <span>
                                                <FaGooglePlay className='md:h-6 max-md:h-5 md:w-6 max-md:w-5 me-1' />
                                            </span>
                                            <span>GOOGLE PLAY</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='md:mt-10 max-md:mt-5'>
                        <h4 className='mb-4'>Fundamental Procedure</h4>
                        <div className='flex gap-4 max-md:flex-col'>
                            <div className='md:w-1/2 px-3'>
                                <ul className="list-none">
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' /><span><b>Download My EV Point Mobile App</b> either from Play store or Apple store.</span></li>
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Click on create account or register and fill your credentials.</li>
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Now, you need to recharge your wallet in order to get access to the features.</li>
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Thereafter, locate your nearest charging station for charging your vehicle.</li>
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />At the charging station, scan a QR code to initiate the charging process.</li>
                                </ul>
                            </div>
                            <div className='md:w-1/2 px-3'>
                                <ul className="list-none">
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Stay alert to your charging status on your app.</li>
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Once the vehicle is charged enough, click on stop charging.</li>
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Now, you are ready to Embark on your journey.</li>
                                    <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />If you need to charge again, follow the same steps.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='mt-10'>
                        <h4 className='mb-3'>Our Main Attractions</h4>
                        <ul className="list-none px-3">
                            <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Locating nearest charging stations easily and effortlessly.</li>
                            <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Easy payments through our application.</li>
                            <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />24/7 customer support.</li>
                            <li className="flex gap-2 mb-2"><img src={ImageIcons.Polygon} alt='Polygon' className='mt-1 h-4 w-4' />Get status of your charging history.</li>
                        </ul>
                    </div>
                    <div className='mt-10'>
                        <p className="mb-3">Embrace the future of mobility with our groundbreaking solution. At myEV point, everything is taken care of from set up to maintenance, allowing you to embrace the future of mobility.</p>
                        <p>So, Hurry up! Download <b>My EV Point Mobile App</b> and get an unbeatable experience of charging your EV.</p>
                    </div>
                </div>
            </section>
        </>
    )
};

export default MobileApp;